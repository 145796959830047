import React from 'react';
import './Common.css';

export default class Dividerblank extends React.Component {
  render() {
    return (
      <div class="row justify-content-center top-margin">
      <div class="col-lg-6 col">
        <div style={{ borderTop: "2px solid #ffffff00"}}></div>
      </div>
      </div>
    );
  }
}

